<template>
  <div>
    <button
      :id="`performance-${performance.id}`"
      class="performance-button py-2 px-4 rounded font-semibold text-sm font-button bg-button-guest-primary text-button-label hover:opacity-80 disabled:cursor-not-allowed disabled:bg-disabled disabled:text-white min-w-[150px] max-w-[200px] truncate uppercase text-center"
      :disabled="disabled"
      :class="buttonClasses"
      @click="onClick"
    >
      {{ buttonText }}
      <span v-if="performance.sold">( SOLD OUT )</span>
    </button>
    <p
      v-if="!isOnSale"
      id="on-sale-message"
      class="text-sm text-fg-disclaimer flex flex-col mt-1 -space-y-0.5"
    >
      <span>Goes on sale</span>
      <span>
        {{ settingStore.fullDate(performance.on_sale_datetime) }} @
        {{ settingStore.timeStr(performance.on_sale_datetime) }}
      </span>
    </p>
    <p
      v-if="hasPurchaseStop"
      id="off-sale-message"
      class="text-sm text-fg-disclaimer flex flex-col mt-1 -space-y-0.5"
    >
      No longer on sale
    </p>
  </div>
</template>

<script lang="ts" setup>
import moment from 'moment'
import { computed, toRefs } from 'vue'

import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { ErrorData } from '@/utils/errors'
import { todayIsoStr } from '@/utils/utils'

import { PerformanceListRead } from '@generated/types'

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (event: 'click', performance: PerformanceListRead): void
}>()

const props = withDefaults(
  defineProps<{
    error: ErrorData
    performance: PerformanceListRead
    forcePurchase?: boolean
    buttonClasses?: string
  }>(),
  { forcePurchase: false },
)
const { error, performance, forcePurchase } = toRefs(props)

const settingStore = useSettingStore()
const timeStr = computed<string>(() => settingStore.timeStr(performance.value.datetime))
const isPast = computed<boolean>(
  () => settingStore.dateIsoStr(performance.value.datetime) < todayIsoStr(),
)
const isOnSale = computed<boolean>(() => {
  if (forcePurchase.value) return true

  if (!performance.value.on_sale_datetime) return true // immediate on sale
  return moment().isAfter(performance.value.on_sale_datetime)
})
const hasPurchaseStop = computed<boolean>(() => {
  if (!performance.value.off_sale_datetime) return isPast.value
  return moment().isAfter(performance.value.off_sale_datetime)
})
const disabled = computed<boolean>(
  () => performance.value.sold || !isOnSale.value || hasPurchaseStop.value,
)

const checkoutStore = useCheckoutStore()
async function onClick() {
  if (performance.value.sold) return
  emit('click', performance.value)
  await checkoutStore.setPerformance(performance.value, error.value)
}

const buttonText = computed<string>(() => {
  return performance.value.button_text || `${timeStr.value} SHOW`
})
</script>
