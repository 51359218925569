<template>
  <section
    :id="uid"
    class="flex flex-col justify-center py-4 w-full bg-none tlvs-container"
    :class="{ dark: !invertColors, 'text-button-label': !invertColors }"
  >
    <div class="flex">
      <div class="w-9/12">
        <div
          v-for="(table, tableIdx) in selectedTables"
          :key="table.id"
          class="flex pl-4 mb-2 last:mb-0"
        >
          <div class="flex flex-wrap grow items-center">
            <div class="w-1/12">
              <div
                class="table-number font-label font-bold mr-2 w-4 h-4 rounded-full text-sm text-center leading-4"
                :class="{
                  'text-button-label bg-primary': invertColors,
                  'text-primary bg-button-label': !invertColors,
                  'flex justify-center items-center': table.isSingleTable,
                }"
              >
                <template v-if="table.isSingleTable">
                  <CheckMark id="single-table-selection-fulfilled" />
                </template>
                <template v-else>{{ tableIdx + 1 }}</template>
              </div>
            </div>
            <div class="w-4/12">
              <div :id="`selected-table-${table.id}-type-and-name`" class="font-sans text-xs">
                <span v-if="table.standing_room">{{ table.name }}</span>
                <span v-else>{{ table.type }}</span>
              </div>
            </div>
            <div
              class="w-6/12"
              :class="{ 'flex justify-start items-baseline': isFeeDisclosureEnable }"
            >
              <div
                :id="`selected-table-${table.id}-num-guests-and-price`"
                class="font-semibold text-xs"
              >
                {{ table.guests }} guest<span v-if="table.guests > 1">s</span> x
                <template v-if="!isFeeDisclosureEnable">
                  {{ currencyRoundUp(table.price_per_person) }}
                </template>
                <template v-else>
                  {{ currency(+table.price_per_person + table.feeDisclosureValue) }}
                </template>
              </div>
              <div
                v-if="isFeeDisclosureEnable"
                class="text-xs ml-2"
                :id="`selected-table-${table.id}-price-and-fee`"
              >
                ({{ currency(table.price_per_person) }} +
                {{ currency(table.feeDisclosureValue) }} fee)
              </div>
            </div>
          </div>
        </div>
        <template v-if="selectable">
          <div :id="`table-${selectedTables.length + 1}-holster`" class="flex">
            <div
              class="table-number font-label font-bold ml-4 mr-2 w-4 h-4 rounded-full text-sm text-center leading-4"
              :class="{
                'text-button-label bg-primary': invertColors,
                'text-primary bg-button-label': !invertColors,
              }"
            >
              {{ selectedTables.length + 1 }}
            </div>
          </div>
        </template>
      </div>
      <template v-if="!selectable">
        <div
          id="total-table-selected-price"
          class="w-2/12 flex items-end justify-end font-sans font-bold text-base leading-4"
        >
          <template v-if="!isFeeDisclosureEnable">
            {{ currencyRoundUp(totalTicketPrice) }}
          </template>
          <template v-else> {{ currency(totalTicketPrice) }} </template>
        </div>
      </template>
    </div>
  </section>
</template>
<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { PropType, defineComponent } from 'vue'

import CheckMark from '@/assets/checkMark.svg?component'
import { EnrichedTable } from '@/orders'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { sum } from '@/utils/utils'

interface SelectedTable extends EnrichedTable {
  isSingleTable: Boolean
  feeDisclosureValue: number
}

export default defineComponent({
  name: 'TableListViewSelected',
  components: {
    CheckMark,
  },
  props: {
    uid: {
      type: String,
      required: false,
    },
    tables: {
      type: Array as PropType<EnrichedTable[]>,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: true,
    },
    totalGuests: {
      type: Number,
      required: true,
    },
    invertColors: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),
    ...mapState(useCheckoutStore, [
      'actualServicePercentage',
      'actualServiceFee',
      'requiresPayment',
    ]),
    ...mapState(useSettingStore, ['currencyRoundUp', 'currency']),

    selectedTables(): SelectedTable[] {
      return this.tables.map(table => ({
        ...table,
        isSingleTable: !this.selectable && this.tables.length === 1,
        feeDisclosureValue:
          this.isFeeDisclosureEnable && this.requiresPayment
            ? (table.price_per_person * this.actualServicePercentage) / 100 + this.actualServiceFee
            : 0,
      }))
    },

    totalTicketPrice() {
      return sum(
        this.selectedTables.map(
          table => Number(table.guests) * (+table.price_per_person + table.feeDisclosureValue),
        ),
      )
    },

    isFeeDisclosureEnable(): boolean {
      return this.settingStore.fee_disclosure_enabled
    },
  },
})
</script>
