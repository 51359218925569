import * as Sentry from '@sentry/browser'
import Agent from 'agentkeepalive'
import axios, { AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import * as _ from 'lodash-es'

import {
  ListResponse,
  PerformanceListResponse,
  RawReservationCreateResponse,
  SummaryReportResponse,
} from '@/api/manual_types'
import { getVersion } from '@/api/version'
import { StoreResidencyId } from '@/modules/residency/stores/ResidencyStore'
import { useSettingStore } from '@/stores/setting'
import { StoreUser } from '@/stores/user'
import { enhanceError, isErrorData } from '@/utils/errors'
import loc from '@/utils/loc'

import {
  AccountingReportRead,
  AddOnRead,
  AddOnWrite,
  CouponRead,
  FloorPlanId,
  FloorPlanRead,
  FloorPlanWrite,
  GuestDetailedRead,
  GuestRead,
  GuestSearchRead,
  GuestWrite,
  MailchimpSettingsRead,
  MailchimpSettingsWrite,
  PerformanceCreate,
  PerformanceDetailedWrite,
  PerformanceId,
  PerformanceQueryParams,
  PerformanceRead,
  PerformanceReportRead,
  PerformanceReservationsRead,
  PerformanceWrite,
  PromotionCodeRead,
  PromotionCodeWrite,
  ReportInstanceId,
  ReportInstanceRead,
  ReportInstanceWrite,
  ReservationCreate,
  ReservationDetailedRead,
  ReservationGuestModify,
  ReservationId,
  ReservationPaidUpdate,
  ReservationRead,
  ReservationUpdate,
  ResidencyId,
  ResidencyPublicRead,
  ResidencyRead,
  ResidencyWrite,
  SeatingTimeRead,
  SeatingTimeWrite,
  SettingRead,
  SettingWrite,
  ShowRead,
  ShowTimeRead,
  ShowTimeWrite,
  ShowWrite,
  StripeReaderId,
  StripeReaderRead,
  SuccessResponse,
  SummaryReportParameters,
  TransactionRead,
  UserId,
  UserRead,
} from '@generated/types'

function isErrorResponse(response: AxiosResponse): boolean {
  return response.data.error && isErrorData(response.data.error)
}

export class UnauthenticatedError extends Error {}
export class ServerFailureError extends Error {}
export class BadRequestError extends Error {}
export class BackendAPIError extends Error {}
export class NoResponseError extends Error {}
export class InvalidVenueError extends Error {}

export async function handleAxiosError(error) {
  if (!axios.isAxiosError(error)) {
    Sentry.captureException(new Error('Axios had an exception not an AxiosError', { cause: error }))
  } else {
    Sentry.setContext('axiosConfig', error.config)
    Sentry.setContext('axiosRequest', error.request || {})
    if (!error.response) {
      Sentry.captureException(
        new NoResponseError(`Recieved no response in api call to ${error.config.url}`, {
          cause: error,
        }),
      )
    } else {
      const status_code = error.response.status
      Sentry.setContext('axiosResponse', error.response)
      if (!isErrorResponse(error.response)) {
        Sentry.captureException(
          new ServerFailureError(
            `Received ill-formated ${status_code} response from ${error.config.url}`,
            { cause: error },
          ),
        )
      } else {
        Sentry.setContext('ttError', error.response.data.error)

        // Standardize our handling of API responses so code above can easily tell the difference between
        // the BE rejecting an invalid request and something going wrong in typescript code,
        error = enhanceError(error, error.response.data.error)
        if (status_code == 400) {
          Sentry.captureException(
            new BadRequestError(`${error.code} in ${error.config.url}`, { cause: error }),
          )
        } else if (status_code == 401 || status_code == 403) {
          console.log(`Handling ${status_code}-${error.code} by redirecting to login`)
          return loc.redirect('/accounts/login/?next=/#' + window.location.hash)
        } else if (status_code >= 500) {
          Sentry.captureException(
            new ServerFailureError(`Recieved ${status_code} in api call to ${error.config.url}`, {
              cause: error,
            }),
          )
        } else {
          Sentry.captureException(
            new BackendAPIError(`Handled ${status_code}-${error.code} in ${error.config.url}`, {
              cause: error,
            }),
          )
        }
      }
    }
  }
  return Promise.reject(error)
}

export function configureBrowserAxios() {
  axiosRetry(axios, { retries: 2, retryDelay: axiosRetry.exponentialDelay })
  axios.interceptors.response.use(response => {
    useSettingStore().updateVenueAppVersion(response.headers['x-tt-version'])
    return response
  }, handleAxiosError)

  axios.defaults.xsrfHeaderName = 'X-CSRFToken'
  axios.defaults.xsrfCookieName = 'csrftoken'
  axios.defaults.headers['X-TT-ClientVersion'] = getVersion()
}

export function configureSsrAxiosOnce() {
  axios.defaults.httpAgent = new Agent({
    keepAlive: true,
    maxSockets: 24,
    maxTotalSockets: 24,
    maxFreeSockets: 4,
    timeout: 60000,
    freeSocketTimeout: 30000,
  })

  axios.interceptors.response.use(
    r => r,
    async error => {
      if (error.response?.status === 404) {
        throw new InvalidVenueError('Invalid Venue')
      }
      return Promise.reject(error)
    },
  )
}

export function configureSsrAxios(host: string) {
  axios.defaults.baseURL = `http://127.0.0.1/` // We're connecting directly to nginx to bypass AWS load balancer
  axios.defaults.headers.common['Host'] = host
}

export function configureDevSsrAxios(host: string) {
  axios.defaults.baseURL = host // Connect to wherever, TT_HOST=https://parker-jazz.turntabletix.com for example
}

export async function getSettings(): Promise<AxiosResponse<SettingRead>> {
  return axios.get(`/api/settings/`)
}

export async function updateSettings(data: SettingWrite): Promise<AxiosResponse<SettingRead>> {
  return axios.put(`/api/settings/`, data)
}

export async function getAddOns(): Promise<AxiosResponse<AddOnRead[]>> {
  return axios.get<AddOnRead[]>(`/api/add-on/`)
}

export async function getFloorPlans(): Promise<AxiosResponse<FloorPlanRead[]>> {
  return axios.get<FloorPlanRead[]>(`/api/floor-plan/`)
}

export async function updateAllAddOns(data: AddOnWrite[]): Promise<AxiosResponse<AddOnRead[]>> {
  return axios.put(`/api/add-on/all/`, data)
}

export async function addFloorPlan(data: FloorPlanWrite) {
  return axios.post(`/api/floor-plan/`, data)
}

export async function addFloorPlanDetailed(
  data: FloorPlanWrite,
): Promise<AxiosResponse<FloorPlanRead>> {
  return axios.post(`/api/floor-plan/`, data)
}

export async function deleteFloorPlan(id: FloorPlanId) {
  return axios.delete(`/api/floor-plan/${id}/`)
}

export async function updateFloorPlanDetailed(
  data: FloorPlanWrite,
): Promise<AxiosResponse<FloorPlanRead>> {
  return axios.put(`/api/floor-plan/${data.id}/`, data)
}

export async function duplicateFloorPlan(id: number): Promise<AxiosResponse<FloorPlanRead>> {
  return axios.post(`/api/floor-plan/${id}/duplicate/`)
}

export async function getShowTimes(): Promise<AxiosResponse<ShowTimeRead[]>> {
  return axios.get(`/api/showtime/`)
}

export async function addShowTime(data: ShowTimeWrite): Promise<AxiosResponse<ShowTimeRead>> {
  return axios.post(`/api/showtime/`, data)
}

export async function updateShowTime(data: ShowTimeWrite): Promise<AxiosResponse<ShowTimeRead>> {
  return axios.put(`/api/showtime/${data.id}/`, data)
}

export async function updateAllShowTimes(
  data: ShowTimeWrite[],
): Promise<AxiosResponse<ShowTimeRead[]>> {
  return axios.put(`/api/showtime/all/`, data)
}

export async function deleteShowTime(id: number) {
  return axios.delete(`/api/showtime/${id}/`)
}

export async function addSeatingTime(
  data: SeatingTimeWrite,
): Promise<AxiosResponse<SeatingTimeRead>> {
  return axios.post(`/api/seating-time/`, data)
}

export async function updateSeatingTime(
  data: SeatingTimeWrite,
): Promise<AxiosResponse<SeatingTimeRead>> {
  return axios.put(`/api/seating-time/${data.id}/`, data)
}

export async function getShow(id: number): Promise<AxiosResponse<ShowRead>> {
  return axios.get(`/api/show/${id}/`)
}

export async function getShows(): Promise<AxiosResponse<ShowRead[]>> {
  return axios.get(`/api/show/`)
}

export async function addShow(data: ShowWrite): Promise<AxiosResponse<ShowRead>> {
  return axios.post(`/api/show/`, data)
}

export async function updateShow(data: ShowWrite): Promise<AxiosResponse<ShowRead>> {
  return axios.put(`/api/show/${data.id}/`, data)
}

export async function updateShowImage(id: number, data): Promise<AxiosResponse<ShowRead>> {
  return axios.put(`/api/show/${id}/set_image/`, data)
}

export async function updateShowImagePosition(id, data): Promise<AxiosResponse<ShowRead>> {
  return axios.put(`/api/show/${id}/set_image_position/`, data)
}

export async function deleteShow(id: number) {
  return axios.delete(`/api/show/${id}/`)
}

export async function duplicateShow(id: number): Promise<AxiosResponse<ShowRead>> {
  return axios.post(`/api/show/${id}/duplicate/`)
}

export async function getPerformances(
  query: PerformanceQueryParams,
): Promise<AxiosResponse<PerformanceListResponse>> {
  return axios.get(
    `/api/performance/?${Object.entries(query)
      .map(entry => entry[0] + '=' + entry[1])
      .join('&')}`,
  )
}

export async function getPerformanceReports(
  query: PerformanceQueryParams,
): Promise<AxiosResponse<PerformanceReportRead[]>> {
  return axios.get(
    `/api/performance/report/?${Object.entries(query)
      .map(entry => entry[0] + '=' + entry[1])
      .join('&')}`,
  )
}

export async function getAccountingSummary(
  month: string,
  type: 'accrual' | 'cash',
): Promise<AxiosResponse<AccountingReportRead[]>> {
  return axios.get(`/api/accounting/${type}/?months=${month}`)
}

export async function getSummaryReports({
  start,
  end,
}: SummaryReportParameters): Promise<AxiosResponse<SummaryReportResponse>> {
  return axios.get(`/api/summary-report/?start=${start}&end=${end}`)
}

export async function getPerformance(
  id: number,
  query?: { invite_code: string },
): Promise<AxiosResponse<PerformanceRead>> {
  return axios.get(
    `/api/performance/${id}/?${Object.entries(query || {})
      .map(entry => entry[0] + '=' + entry[1])
      .join('&')}`,
  )
}

export async function addPerformance(
  data: PerformanceCreate,
): Promise<AxiosResponse<PerformanceRead>> {
  return axios.post(`/api/performance/`, data)
}

export async function updatePerformance(
  data: PerformanceWrite,
): Promise<AxiosResponse<PerformanceRead>> {
  return axios.put(`/api/performance/${data.id}/`, data)
}

export async function updatePerformanceDetailed(
  data: PerformanceDetailedWrite,
): Promise<AxiosResponse<PerformanceRead>> {
  return axios.put(`/api/performance/${data.id}/detailed/`, data)
}

export async function deletePerformance(id: number) {
  return axios.delete(`/api/performance/${id}/`)
}

export async function getReservations(params?: {
  active: boolean
}): Promise<AxiosResponse<ListResponse<ReservationRead>>> {
  return axios.get(
    `/api/reservation/?${Object.entries(params || {})
      .map(entry => entry[0] + '=' + entry[1])
      .join('&')}`,
  )
}

export async function getReservation(
  id: ReservationId,
): Promise<AxiosResponse<ReservationDetailedRead>> {
  return axios.get(`/api/reservation/${id}/`)
}

export async function getReservationsByPerformance(
  performanceId: PerformanceId,
): Promise<AxiosResponse<ReservationDetailedRead[]>> {
  return axios.get(`/api/reservation/by-performance/?performance_id=${performanceId}`)
}

export async function getPerformanceReservations(
  id: number,
): Promise<AxiosResponse<PerformanceReservationsRead>> {
  return axios.get(`/api/performance/${id}/reservations/`)
}

export async function reserveReservation(
  data: ReservationCreate,
): Promise<AxiosResponse<RawReservationCreateResponse>> {
  return axios.post(`/api/reservation/reserve/`, data)
}

export async function addReservation(
  data: ReservationCreate,
): Promise<AxiosResponse<RawReservationCreateResponse>> {
  return axios.post(`/api/reservation/`, data)
}

export async function retryReservation(
  id: ReservationId,
  reader_id?: StripeReaderId,
): Promise<AxiosResponse> {
  const query = reader_id ? `?reader_id=${reader_id}` : ''
  return axios.post(`/api/reservation/${id}/retry/${query}`)
}

export async function updateReservation(
  data: ReservationUpdate,
): Promise<AxiosResponse<ReservationRead>> {
  return axios.put(`/api/reservation/${data.id}/`, data)
}

export async function paidUpdateReservation(
  data: ReservationPaidUpdate,
): Promise<AxiosResponse<ReservationRead>> {
  return axios.post(`/api/reservation/${data.id}/paid/`, data)
}

export async function payReservation(
  id: ReservationId,
  reader_id: StripeReaderId,
): Promise<AxiosResponse<SuccessResponse>> {
  return axios.post(`/api/reservation/${id}/pay/`, { reader_id })
}

export async function refundTransaction(data: {
  transactionId: number
  amount: number
}): Promise<AxiosResponse<TransactionRead>> {
  return axios.post(`/api/transaction/${data.transactionId}/refund/`, { amount: data.amount })
}

export async function abandonReservation(
  id: ReservationId,
  data?: Pick<ReservationGuestModify, 'reservation_key'>,
): Promise<AxiosResponse> {
  return axios.post(`/api/reservation/${id}/abandon/`, data)
}

export async function resendConfirmationEmail(id: number, email: string): Promise<AxiosResponse> {
  return axios.post(`/api/reservation/${id}/resend-confirmation/`, { email })
}

export async function deleteReservation(id: number) {
  return axios.delete(`/api/reservation/${id}/`)
}

export async function getReservationStatus(
  id: ReservationId,
): Promise<AxiosResponse<ReservationRead>> {
  return axios.get(`/api/reservation/${id}/status/`)
}

export async function validatePromotionCode(code: string) {
  return axios.get(`/api/promotion-code/validate/`, { params: { code } })
}

export async function updateAllPromotionCodes(data: PromotionCodeWrite[]) {
  return axios.put(`/api/promotion-code/all/`, data)
}

export async function getCoupons(): Promise<AxiosResponse<CouponRead[]>> {
  return axios.get(`/api/coupon/`)
}

export async function getPromotionCodes(): Promise<AxiosResponse<PromotionCodeRead[]>> {
  return axios.get(`/api/promotion-code/`)
}

export async function searchGuests(options: {
  query: string
  field?: string
}): Promise<AxiosResponse<GuestSearchRead[]>> {
  const params = { q: options.query }
  if (options.field) {
    params['field'] = options.field
  }
  return axios.get(`/api/guest/search/`, { params })
}

export async function getGuest(id: number): Promise<AxiosResponse<GuestDetailedRead>> {
  return axios.get(`/api/guest/${id}/reservations/`)
}

export async function updateGuest(data: GuestWrite): Promise<AxiosResponse<GuestRead>> {
  return axios.put(`/api/guest/${data.id}/`, data)
}

export async function mergeGuest(
  sourceId: number,
  targetId: number,
): Promise<AxiosResponse<GuestRead>> {
  return axios.post(`/api/guest/${sourceId}/merge-into/${targetId}/`)
}

export async function getMe(): Promise<AxiosResponse<UserRead>> {
  return axios.get(`/api/user/profile/`)
}

export async function getMeOnboarding(
  userId: UserId,
  token: string,
): Promise<AxiosResponse<UserRead>> {
  return axios.get(`/api/user/${userId}/onboarding/?token=${token}`)
}

export async function resetPassword(
  userId: UserId,
  token: string,
  password: string,
): Promise<AxiosResponse<UserRead>> {
  return axios.post(`/api/user/${userId}/reset_password/?token=${token}`, { password })
}

export async function sendResetPasswordEmail(userId: UserId): Promise<AxiosResponse> {
  return axios.post(`/api/user/${userId}/send_reset_password_email/`)
}

export async function getUsers(): Promise<AxiosResponse<UserRead[]>> {
  return axios.get(`/api/user/`)
}

export async function updateAllUsers(data: StoreUser[]): Promise<AxiosResponse<StoreUser[]>> {
  return axios.put(`/api/user/all/`, data)
}

export async function getReaders(): Promise<AxiosResponse<StripeReaderRead[]>> {
  return axios.get(`/api/stripe/reader/`)
}

export async function cancelReaderAction(
  readerId: StripeReaderId,
): Promise<AxiosResponse<StripeReaderRead>> {
  return axios.post(`/api/stripe/reader/cancel/`, { reader_id: readerId })
}

export async function simulatePayment(
  readerId: StripeReaderId,
  cardNumber: string,
): Promise<AxiosResponse<StripeReaderRead>> {
  return axios.post(`/api/stripe/reader/simulate/`, {
    reader_id: readerId,
    card_number: cardNumber,
  })
}

export async function createReportInstance(
  report: ReportInstanceWrite,
): Promise<AxiosResponse<ReportInstanceRead>> {
  return axios.post(`/api/report/`, report)
}

export async function getReportInstance(
  id: ReportInstanceId,
): Promise<AxiosResponse<ReportInstanceRead>> {
  return axios.get(`/api/report/${id}/`)
}

export async function getReportInstances(): Promise<AxiosResponse<ReportInstanceRead[]>> {
  return axios.get(`/api/report/`)
}

export async function createResidency(
  residency: ResidencyWrite,
): Promise<AxiosResponse<ResidencyRead>> {
  return axios.post(
    `/api/residency/`,
    _.pick(residency, ['name', 'description', 'show_id', 'performance_ids']),
  )
}

export async function getResidencies(): Promise<AxiosResponse<ResidencyRead[]>> {
  return axios.get(`/api/residency/`)
}

export async function updateResidency(
  residency: ResidencyWrite,
): Promise<AxiosResponse<ResidencyRead>> {
  return axios.put(
    `/api/residency/${residency.id}/`,
    _.pick(residency, ['id', 'name', 'description', 'show_id', 'performance_ids', 'invite_codes']),
  )
}

export async function deleteResidency(id: StoreResidencyId) {
  return axios.delete(`/api/residency/${id}/`)
}

export async function getResidency(
  id: ResidencyId | string,
): Promise<AxiosResponse<ResidencyPublicRead>> {
  return axios.get(`/api/residency/${id}/`)
}

export async function getMailchimpSettings(): Promise<AxiosResponse<MailchimpSettingsRead>> {
  return axios.get(`/api/mailchimp/`)
}

export async function updateMailchimpSettings(
  settings: MailchimpSettingsWrite,
): Promise<AxiosResponse<MailchimpSettingsRead>> {
  return axios.put(`/api/mailchimp/`, settings)
}

export async function getMailchimpAudiences() {
  return axios.get(`/api/mailchimp/audiences/`)
}

export async function disconnectMailchimp() {
  return axios.post(`/api/mailchimp/disconnect/`)
}

export async function createTemplate(
  performanceIds: PerformanceId[],
): Promise<AxiosResponse<{ template_url: string }>> {
  return axios.post(`/api/mailchimp/create-template/`, { performance_ids: performanceIds })
}

export async function guestModifyReservation(
  reservationId: ReservationId,
  guest: ReservationGuestModify,
): Promise<AxiosResponse<ReservationRead>> {
  return axios.post(`/api/reservation/${reservationId}/modify/`, guest)
}
