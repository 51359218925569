<template>
  <section>
    <StatusBar v-if="!isCheckoutDesktopFlow" />
    <div :class="{ 'px-4': !isCheckoutDesktopFlow }">
      <h1 class="text-2xl md:text-4xl font-bold font-heading mb-2">Payment</h1>
      <p>All fields are required.</p>

      <div
        v-if="ui.error.code"
        id="error-display"
        class="p-2 bg-red-200 text-red-600 border border-red-400 my-4 rounded"
      >
        <template v-if="ui.error.code == 'table_unavailable'">
          Sorry, table is already reserved. Please select other tables
          <a @click.prevent="selectTables">here</a>.
        </template>

        <template v-else>
          {{ ui.error.message }}
        </template>
      </div>

      <div class="mt-6 flex flex-col">
        <div class="w-full xl:w-1/2 ml-auto">
          <form @submit.prevent class="mb-8">
            <div class="flex flex-col space-y-2 xs:flex-row xs:space-y-0">
              <TextInput
                id="fname"
                forInput="fname-input"
                v-model="contact.first_name"
                label="First Name"
                classes="w-full xs:w-1/2 xs:pr-2"
              />
              <TextInput
                id="lname"
                forInput="lname-input"
                v-model="contact.last_name"
                label="Last Name"
                classes="w-full xs:w-1/2"
              />
            </div>
            <div class="flex flex-col space-y-2 mt-4 xs:flex-row xs:space-y-0">
              <TextInput
                id="email"
                forInput="email-input"
                type="email"
                v-model="contact.email"
                label="Email"
                classes="w-full xs:w-3/5 xs:pr-2"
              />
              <TextInput
                id="tel"
                forInput="tel-input"
                type="tel"
                v-model="contact.phone"
                label="Phone"
                classes="w-full xs:w-2/5"
              />
            </div>

            <template v-if="isStripe && stripeEle">
              <StripeElements
                :stripeKey="stripeKey"
                :instanceOptions="stripeInstanceOptions"
                :elementsOptions="{}"
                #default="{ elements }"
                ref="elms"
              >
                <div class="flex flex-wrap mt-8 sm:flex-nowrap sm:space-x-2">
                  <div class="w-full sm:w-1/2">
                    <div class="font-label text-fg-disclaimer text-sm">Card Number</div>
                    <div
                      class="bg-input font-sans rounded border border-linear-g-primary px-4 py-2 card-input"
                    >
                      <StripeElement
                        type="cardNumber"
                        ref="card"
                        @change="validation.cardNumber = $event.complete"
                        :elements="elements"
                        :options="{ style: cardStyles }"
                      />
                    </div>
                  </div>
                  <div class="w-1/2 pr-2 mt-2 sm:w-1/6 sm:mt-0 sm:pr-0">
                    <div class="font-label text-fg-disclaimer text-sm">CVC</div>
                    <div
                      class="bg-input font-sans rounded border border-linear-g-primary px-4 py-2"
                    >
                      <StripeElement
                        type="cardCvc"
                        ref="cvc"
                        @change="validation.cardCVC = $event.complete"
                        :elements="elements"
                        :options="{ style: cardStyles }"
                      />
                    </div>
                  </div>
                  <div class="w-1/2 mt-2 sm:w-2/6 sm:mt-0">
                    <div class="font-label text-fg-disclaimer text-sm">Expiration (MM/YY)</div>
                    <div
                      class="bg-input font-sans rounded border border-linear-g-primary px-4 py-2"
                    >
                      <StripeElement
                        type="cardExpiry"
                        ref="cardExpiry"
                        @change="validation.cardExpiry = $event.complete"
                        :elements="elements"
                        :options="{ style: cardStyles }"
                      />
                    </div>
                  </div>
                </div>
              </StripeElements>
            </template>
          </form>
          <CheckboxInput
            id="accepts-marketing"
            v-model="contact.accepts_marketing"
            :label="`Keep me informed of upcoming ${settingStore.venue_name} shows`"
            guest
          />
          <p v-show="checkoutText" class="text-sm text-fg-g-primary mt-7">
            {{ checkoutText }}
          </p>
          <div v-if="checkout_confirmation_text" class="mt-7">
            <CheckboxInput
              id="accepts-checkout-confirmation"
              v-model="acceptsCheckoutConfirmation"
              label="I agree to the following policy"
              guest
            />
            <div
              class="text-sm text-fg-g-primary mt-2 whitespace-pre-line"
              id="checkout-confirmation-text"
            >
              {{ checkout_confirmation_text }}
            </div>
          </div>
        </div>

        <div v-if="!isCheckoutDesktopFlow" class="price-information">
          <CheckoutDetailTable />
          <CheckoutPromoCode class="my-8" />
        </div>

        <CheckoutBottomBar
          :nextButtonText="saveButtonText"
          :isNextButtonDisabled="paying || !isFormValid"
          @click:next="onSave"
        />
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.form-row {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;

  .form-input {
    background-color: #e6e6e6;
    border: none;
    padding: 5px 10px;
    width: 190px;
    font-size: 16px;
  }

  .card-input,
  .email-input {
    width: 250px;
  }
}

@media screen and (max-width: 767px) {
  .form-row {
    display: block;

    .form-input {
      width: 90%;
    }
  }
}

.price-information {
  .tax-information {
    .tax-details {
      border: 2px solid #d5d5d5;
      text-align: center;
      font-size: 11px;
      color: #717171;
      font-weight: 500;
      cursor: pointer;

      width: 15px;
      height: 15px;
      line-height: 12px;
      border-radius: 7.5px;
    }

    .tax-tooltip {
      display: inline-block;
      position: absolute;
      font-size: 10px;
      color: black;
      width: 120px;
      border: 2px solid #dddddd;
      border-radius: 5px;
      padding: 4px;
      transform: translate(10px, -10px);
      box-shadow: 5px 5px 5px #eeeeee;

      & > * {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.line {
  background: #9d9d9d;
  height: 2px;
}

.label {
  color: #595959;
}
</style>

<script lang="ts">
import { useCssVar } from '@vueuse/core'
import { mapState, mapStores } from 'pinia'
import { computed, defineComponent } from 'vue'
import { StripeElement, StripeElements } from 'vue-stripe-js'

import ButtonDefault from '@/common/components/atoms/ButtonDefault.vue'
import CheckboxInput from '@/common/components/molecules/CheckboxInput.vue'
import TextInput from '@/common/components/molecules/TextInput.vue'
import { ROOM_MAP_BG_MODE } from '@/common/interfaces/RoomMap'
import CheckoutBottomBar from '@/modules/guestUi/components/organisms/CheckoutBottomBar.vue'
import CheckoutDetailTable from '@/modules/guestUi/components/organisms/CheckoutDetailTable.vue'
import CheckoutPromoCode from '@/modules/guestUi/components/organisms/CheckoutPromoCode.vue'
import StatusBar from '@/modules/guestUi/components/organisms/StatusBar.vue'
import { EnrichedAddOn } from '@/orders'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { useUserStore } from '@/stores/user'
import { blankError, clearError, displayHandled } from '@/utils/errors'
import { sortBy } from '@/utils/utils'

import { FeatureFlag } from '@generated/types'

export default defineComponent({
  name: 'CheckoutView',
  components: {
    CheckoutBottomBar,
    TextInput,
    CheckoutPromoCode,
    CheckoutDetailTable,
    ButtonDefault,
    CheckboxInput,
    StatusBar,
    StripeElement,
    StripeElements,
  },
  setup() {
    const isDarkMode = useCssVar('--mode')
    const cardStyles = computed(() => ({
      base: {
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '24px',
        color: isDarkMode.value === ROOM_MAP_BG_MODE.FORCE_DARK ? '#fff' : '#000',
      },
    }))
    return { cardStyles }
  },
  props: {},
  data() {
    return {
      acceptsCheckoutConfirmation: false,
      tooltipVisible: false,
      stripeKey: '',
      stripeInstanceOptions: {},
      paying: false,
      validation: {
        cardNumber: false,
        cardCVC: false,
        cardExpiry: false,
      },
      ui: {
        error: blankError(),
      },
    }
  },

  async created() {
    await this.settingStore.loading
    if (this.settingStore.public_key != 'pk_test_xxx') {
      // We want to avoid attempting to load stripe when we know we are running a test
      this.stripeKey = this.settingStore.public_key
    }
    if (this.settingStore.stripe_account) {
      this.stripeInstanceOptions = { stripeAccount: this.settingStore.stripe_account }
    }
  },

  computed: {
    ...mapStores(useSettingStore, useCheckoutStore, useUserStore),
    ...mapState(useCheckoutStore, ['add_ons', 'contact', 'hasPromoCodeApplied', 'performance']),
    ...mapState(useSettingStore, ['currency', 'checkout_confirmation_text']),

    checkoutText(): string {
      return this.settingStore.checkout_text
    },

    hasPerformanceAddOns(): boolean {
      return !!this.performance?.add_ons
    },

    isFormValid(): boolean {
      if (!this.isStripe) return this.checkoutStore.hasSufficientGuestInfo
      return (
        (!this.checkout_confirmation_text || this.acceptsCheckoutConfirmation) &&
        this.checkoutStore.hasSufficientGuestInfo &&
        this.validation.cardNumber &&
        this.validation.cardExpiry &&
        this.validation.cardCVC
      )
    },

    isStripe(): boolean {
      return this.checkoutStore.pricing.total > 0.5
    },

    hasPromos(): boolean {
      return this.settingStore.flagEnabled(FeatureFlag.PROMO_CODES)
    },

    promoCodeDisplay(): string {
      const code = this.checkoutStore.promo_code
      if (!code) return ''
      return `${code.name} (${code.coupon.name})`
    },

    stripeEle(): boolean {
      return !!this.stripeKey
    },

    addOnsInTable(): EnrichedAddOn[] {
      return sortBy(this.checkoutStore.addOnsModels || [], 'name')
    },

    isCheckoutDesktopFlow(): boolean {
      return this.checkoutStore.isDesktopFlow
    },

    saveButtonText(): string {
      if (this.paying) return 'Processing...'
      return 'Purchase'
    },
  },

  methods: {
    async onBack(): Promise<void> {
      history.go(-1)
    },

    selectTables(): void {
      this.$router.push({ name: 'setTable' }).catch(() => {})
    },

    async onSave(): Promise<void> {
      if (this.paying || !this.isFormValid) return
      this.paying = true
      clearError(this.ui.error)

      try {
        await this.checkoutStore.checkout(async () => {
          // @ts-ignore
          return await this.$refs.elms?.instance.confirmCardPayment(
            this.checkoutStore.client_secret,
            {
              payment_method: {
                // @ts-ignore
                card: this.$refs.card.stripeElement,
              },
            },
          )
        })
      } catch (error) {
        displayHandled(error, this.ui.error)
      } finally {
        this.paying = false
      }
    },
  },
})
</script>
