<template>
  <div
    v-show="!checkout_success"
    id="reservation-checkout-timer"
    class="flex flex-col items-baseline gap-0 text-sm"
    :class="{ 'text-alerts-warning': isLessThan3Minutes }"
  >
    <p id="holding-text">{{ holdingText }}</p>
    <p id="time" class="font-semibold">{{ formattedTime }}</p>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, ref, watch } from 'vue'

import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

const settingStore = useSettingStore()
const checkoutStore = useCheckoutStore()
const { tables, reservation, checkout_success } = storeToRefs(checkoutStore)

const remainingSeconds = ref<number>(0)
const isLessThan3Minutes = computed<boolean>(() => remainingSeconds.value < 60 * 3)
function setRemainingSeconds() {
  if (!reservation.value) return
  const expirationAsMoment = settingStore.parseMoment(reservation.value.expiration)
  const nowAsMoment = settingStore.parseMoment(settingStore.nowDateTime())
  remainingSeconds.value = expirationAsMoment.diff(nowAsMoment, 'seconds')
}
setRemainingSeconds()
const interval = setInterval(setRemainingSeconds, 1000)
watch(remainingSeconds, () => {
  if (remainingSeconds.value <= 0) clearInterval(interval)
})
onBeforeUnmount(() => {
  clearInterval(interval)
})

const isExpired = computed<boolean>(() => remainingSeconds.value <= 0)
const holdingText = computed<string>(() => {
  if (isExpired.value) return 'Hold Expired'
  return tables.value.length > 1 ? 'Holding Tables' : 'Holding Table'
})

const formattedTime = computed<string>(() => {
  if (isExpired.value) return '00:00'
  const minutes = Math.floor(remainingSeconds.value / 60).toString()
  const seconds = (remainingSeconds.value % 60).toString()
  return `${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
})
</script>
