<template>
  <div class="details flex flex-col gap-2 md:flex-row">
    <div class="meta w-full md:w-1/4">
      <picture class="show-image">
        <source :srcset="show.srcset.rectLg.src" type="image/webp" media="(max-width: 768px)" />
        <source :srcset="show.srcset.sqLg.src" type="image/webp" />
        <img :src="show.srcset.original.src" :alt="show.name" class="w-full h-auto" />
      </picture>
    </div>

    <div class="performances whitespace-pre-line w-full md:w-3/4">
      <div class="flex justify-start items-center">
        <h3 class="text-3xl font-semibold font-heading mr-auto" :id="`show-${show.id}-name`">
          {{ show.name }}
        </h3>

        <router-link
          :id="`residency-link-${show.id}`"
          class="residency-button mr-2 py-1 px-3 rounded-full border border-current text-xs"
          v-if="firstResidencySlug"
          :to="{ name: 'residency-hub', params: { slug: firstResidencySlug } }"
        >
          Residency
        </router-link>

        <router-link
          v-show="descriptionDisplayType === 'short'"
          class="show-link"
          :id="`show-${show.id}-date-${show.date}`"
          :to="{
            name: 'showDetails',
            params: { showId: show.id },
            query: { date: show.date },
          }"
          @click="emit('showLink:click', show)"
          aria-label="Go to Show Detail"
        >
          <LinkIcon class="w-6 h-6" />
        </router-link>
      </div>

      <div class="uppercase text-base font-semibold mb-3 font-date text-secondary">
        <h4 class="day-of-week" :id="`show-${show.id}-${show.date}-desc`">
          {{ show.dateStr }}
        </h4>
      </div>

      <p
        class="relative text-sm md:text-base font-sans"
        :id="`show-${show.id}-description`"
        ref="descriptionContainer"
        :class="descriptionDisplayType === 'short' ? 'line-clamp-[18]' : 'line-clamp-none'"
      >
        <span ref="descriptionContent">{{ show.description }}&nbsp;</span>
        <button
          v-if="displayReadMoreText || keepReadMoreText"
          class="read-more inline-block bg-body-primary absolute pl-2 bottom-0 right-0 text-button-guest-primary underline decoration-button-guest-primary"
          @click="emit('readMore:click', show)"
          :class="{ 'relative inline pl-0': descriptionDisplayType === 'long' }"
        >
          <slot name="readMoreText">Read More</slot>
        </button>
      </p>

      <div
        class="flex flex-wrap justify-start items-start gap-2 pt-4 pb-4 border-t border-linear-g-primary mt-4 md:border-t-0 md:mt-0 md:pb-0 bg-body-primary sticky bottom-0 sm:gap-4 md:bg-transparent md:relative"
      >
        <PerformanceButton
          class="performance-btn"
          v-for="performance in show.performances"
          :key="performance.id"
          :forcePurchase="show.forcePurchaseForPerformanceId === performance.id"
          :performance="performance"
          :error="ui.error"
          :buttonClasses="`performance-${performance.testId}`"
          @click="emit('performance:click', performance)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useElementBounding } from '@vueuse/core'
import { computed, ref, toRefs } from 'vue'

import LinkIcon from '@/assets/link.svg?component'
import { StorePerformance } from '@/models'
import PerformanceButton from '@/modules/guestUi/components/organisms/PerformanceButton.vue'
import { DisplayShow } from '@/modules/guestUi/interfaces/shows'
import { ErrorData } from '@/utils/errors'

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: 'performance:click', performance: StorePerformance): void
  // eslint-disable-next-line no-unused-vars
  (e: 'showLink:click', show: DisplayShow): void
  // eslint-disable-next-line no-unused-vars
  (e: 'readMore:click', show: DisplayShow): void
}>()

const props = withDefaults(
  defineProps<{
    show: DisplayShow
    ui: { error: ErrorData }
    descriptionDisplayType?: 'long' | 'short'
    keepReadMoreText?: boolean
  }>(),
  {
    descriptionDisplayType: 'short',
    keepReadMoreText: false,
  },
)
const { descriptionDisplayType, show } = toRefs(props)

const descriptionContainer = ref<HTMLElement | null>(null)
const { height: containerHeight } = useElementBounding(descriptionContainer)

const descriptionContent = ref<HTMLElement | null>(null)
const { height: contentHeight } = useElementBounding(descriptionContent)

const displayReadMoreText = computed(() => containerHeight.value < contentHeight.value)

const firstResidencySlug = computed<string | undefined>(function () {
  return show.value.performances.flatMap(p => p.residencies)[0]?.slug
})
</script>
