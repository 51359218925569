import * as Sentry from '@sentry/vue'
import * as stripe from '@stripe/stripe-js'
import { defineStore } from 'pinia'
import { RouteLocationRaw } from 'vue-router'

import * as api from '@/api/api'
import {
  StorePerformance,
  StorePerformanceSeatingTime,
  StorePromotionCode,
  StoreReservation,
  parsePerformance,
} from '@/models'
import {
  EnrichedAddOn,
  EnrichedTable,
  OrderPricing,
  getPricing,
  priceAddOns,
  tablesForSeatedParty,
  toReservationParams,
} from '@/orders'
import { useSettingStore } from '@/stores/setting'
import { StoreUser, useUserStore } from '@/stores/user'
import { ErrorData, clearError, enhanceStripeError } from '@/utils/errors'
import { gtmEvt } from '@/utils/utils'

import {
  FeatureFlag,
  PerformanceAddOnRead,
  PerformanceId,
  PerformanceListRead,
  PerformanceQueryParams,
  PerformanceRead,
  PerformanceSeatingTimeId,
  PerformanceTableId,
  PromotionCodeRead,
  ReservationAddOnWrite,
  ReservationGuestModify,
  ResidencyId,
} from '@generated/types'

export interface Checkout {
  performances: StorePerformance[]
  reservation: StoreReservation | null
  reservation_key: string | null
  performance: StorePerformance | null
  tables: number[]
  seating_time: { id: PerformanceSeatingTimeId; time: string; datetime: string } | null
  number_of_guests: number
  add_ons: ReservationAddOnWrite[] | null
  contact: Contact
  invite_code: string | null
  promo_code: PromotionCodeRead | null
  scroll_position_from_set_show: number | null
  client_secret: string | null
  is_desktop_flow: boolean
  checkout_success: boolean
}

export interface Contact {
  email: string
  phone: string
  first_name: string
  last_name: string
  accepts_marketing: boolean
}

export const useCheckoutStore = defineStore({
  id: 'checkout',
  persist: true,
  state: (): Checkout => {
    return {
      performances: [],
      performance: null,
      number_of_guests: -1,
      seating_time: null,
      tables: [],
      add_ons: null,
      reservation: null,
      reservation_key: null,
      contact: {
        email: '',
        phone: '',
        first_name: '',
        last_name: '',
        accepts_marketing: true,
      } as Contact,
      invite_code: null,
      promo_code: null,
      scroll_position_from_set_show: null,
      client_secret: null,
      is_desktop_flow: false,
      checkout_success: false,
    }
  },
  getters: {
    nextRoute(): RouteLocationRaw {
      if (this.isCheckoutReady) {
        return { name: 'checkout' }
      } else if (this.isAddOnsReady) {
        return { name: 'setAddOns' }
      } else if (this.isSetSeatingTimeReady) {
        return { name: 'setSeatingTime' }
      } else if (this.isSetTablesReady) {
        return { name: 'setTable' }
      } else {
        return { name: 'setGuests' }
      }
    },
    reservationGuestData(): ReservationGuestModify {
      if (!this.performance) return {}
      const result: ReservationGuestModify = {
        reservation_key: this.reservation_key || '',
        tables: this.tables,
        tables_details: this.tableModels.map(t => ({
          id: t.id,
          name: t.name,
          guests: t.guests,
          price_per_person: t.price_per_person,
        })),
        number_of_guests: this.number_of_guests,
        ...toReservationParams(this.pricing),
      }
      if (this.hasSufficientGuestInfo) {
        result.first_name = this.contact.first_name
        result.last_name = this.contact.last_name
        result.email = this.contact.email
        result.phone = this.contact.phone
        result.accepts_marketing = this.contact.accepts_marketing
      } else {
        result.skip_guest = true
      }
      if (this.seating_time) result.seating_time = this.seating_time.id
      if (this.invite_code) result.invite_code = this.invite_code
      if (this.promo_code) result.promo_code = this.promo_code.code
      if (this.add_ons) result.add_ons = this.add_ons
      return result
    },
    activeAddOns(): PerformanceAddOnRead[] {
      return (this.performance?.add_ons || []).filter(a => a.is_active)
    },
    hasAddOns(): boolean {
      return this.activeAddOns.length > 0
    },
    hasSelectedAddOns(): boolean {
      return this.add_ons !== null || !this.hasAddOns
    },
    hasSelectedSeatingTime(): boolean {
      return !!this.seating_time && this.seating_time?.id > 0
    },
    isSetGuestsReady(): boolean {
      return !!this.performance
    },
    isSetTablesReady(): boolean {
      return this.isSetGuestsReady && this.number_of_guests > 0
    },
    isSetSeatingTimeReady(): boolean {
      return this.isSetTablesReady && this.tables && this.tables.length > 0
    },
    isAddOnsReady(): boolean {
      return this.isSetSeatingTimeReady && this.hasSelectedSeatingTime && this.hasAddOns
    },
    isCheckoutReady(): boolean {
      return this.isSetSeatingTimeReady && this.hasSelectedSeatingTime && this.hasSelectedAddOns
    },
    isDetailsReady(): boolean {
      return this.isCheckoutReady && !!this.reservation?.id
    },
    hasPromoCodeApplied(): boolean {
      if (!this.promo_code) return false
      return true
    },
    hasSufficientGuestInfo(): boolean {
      return (
        /\S+@\S+\.\S+/.test(this.contact.email) &&
        this.contact.first_name.length > 1 &&
        this.contact.last_name.length > 1 &&
        this.contact.phone.length > 1
      )
    },
    requiresPayment(): boolean {
      return this.pricing.total > 0.5
    },
    tableModels(): EnrichedTable[] {
      return tablesForSeatedParty(this.performance, this.tables, this.number_of_guests)
    },
    addOnsModels(): EnrichedAddOn[] {
      if (!this.performance?.add_ons) return []
      return priceAddOns(this.performance.add_ons, this.add_ons)
    },
    percentOff(): number {
      return this.promo_code?.coupon?.percent_off || 0
    },
    // eslint-disable-next-line no-unused-vars
    discount(): (_: number) => number {
      // if this.percentOff is 0, then this becomes value * (1 - 0/100)
      return (value: number) => value * (1 - this.percentOff / 100)
    },
    pricing(): OrderPricing {
      return getPricing({
        tables: this.tableModels,
        addOns: this.addOnsModels,
        numberOfGuests: this.number_of_guests,
        serviceFeePerGuest: this.actualServiceFee,
        servicePercentage: this.actualServicePercentage,
        taxRate: useSettingStore().tax_rate,
        feeTaxRate: useSettingStore().fee_tax_rate,
        percentOff: this.percentOff,
        applicationFee: useSettingStore().application_fee,
        applicationFeePerReservation: useSettingStore().application_fee_per_reservation,
      })
    },
    scrollPositionFromSetShow(): number | null {
      return this.scroll_position_from_set_show
    },
    isDesktopFlow(): boolean {
      return this.is_desktop_flow
    },
    standingRoomOnly(): boolean {
      return this.performance?.tables?.length === 1 && this.performance?.tables[0].standing_room
    },
    actualServiceFee(): number {
      const settingStore = useSettingStore()
      if (!this.performance || this.performance.service_fee === null) {
        return settingStore.service_fee
      }
      return Number(this.performance.service_fee)
    },
    actualServicePercentage(): number {
      const settingStore = useSettingStore()
      if (!this.performance || this.performance.service_percentage === null) {
        return settingStore.service_percentage
      }
      return Number(this.performance.service_percentage)
    },
  },
  actions: {
    clearAddOns() {
      this.add_ons = null
      this.clearReservation()
    },
    clearReservation() {
      this.promo_code = null
    },
    clearSeatingTime() {
      this.seating_time = null
      this.clearReservation()
    },
    clearTables() {
      this.tables = []
      this.clearSeatingTime()
    },
    clearNumberOfGuests() {
      this.number_of_guests = 0
      this.clearTables()
    },
    clearAll() {
      this.performance = null
      this.reservation = null
      this.reservation_key = null
      this.client_secret = null
      this.number_of_guests = -1
      this.tables = []
      this.seating_time = null
      this.add_ons = null
      this.promo_code = null
      this.checkout_success = false
    },

    async getPerformances(query: PerformanceQueryParams) {
      const response = await api.getPerformances(query || {})
      this.storePerformances(response.data.results)
      return {
        total: response.data.count,
        pageSize: response.data.pageSize,
      }
    },

    async getPerformance(id: number): Promise<StorePerformance> {
      const response = await api.getPerformance(id)
      return parsePerformance(response.data)
    },

    getLastestPerformanceDatetime(
      performance: PerformanceRead,
      datetimePropsToCompare: (keyof Pick<
        PerformanceRead,
        'created' | 'publish_datetime' | 'on_sale_datetime' | 'off_sale_datetime' | 'datetime'
      >)[],
    ): string | null {
      const settingStore = useSettingStore()
      const nowMoment = settingStore.parseMoment(settingStore.nowDateTime())
      let latestDatetime = performance[datetimePropsToCompare[0]]
      for (const key of datetimePropsToCompare) {
        if (!performance[key]) continue
        if (
          performance[key] &&
          nowMoment.isBefore(settingStore.parseMoment(performance[key] as string))
        ) {
          latestDatetime = performance[key] as string
        }
      }
      return latestDatetime
    },

    isPerformanceVisibleOnTheList(performance: PerformanceRead): boolean {
      const settingStore = useSettingStore()
      const nowMoment = settingStore.parseMoment(settingStore.nowDateTime())
      return nowMoment.isBefore(
        this.getLastestPerformanceDatetime(performance, ['off_sale_datetime', 'datetime']),
      )
    },

    async getPerformanceWithInvite(performanceId: PerformanceId, inviteCode: string) {
      return await api.getPerformance(performanceId, { invite_code: inviteCode })
    },

    storePerformances(performances: PerformanceListRead[]) {
      this.performances = performances.map(parsePerformance)
    },

    async getResidency(residencyId: ResidencyId | string) {
      const response = await api.getResidency(residencyId)
      return response.data
    },

    async refreshPerformance() {
      if (!this.performance?.id) throw new Error('No performance id is set')
      const response = await api.getPerformance(
        this.performance.id,
        this.invite_code ? { invite_code: this.invite_code } : undefined,
      )
      this.performance = parsePerformance(response.data)
    },

    async setPerformance(performance: StorePerformance, error: ErrorData) {
      clearError(error)
      gtmEvt('setPerformance')
      try {
        if (performance.sold) {
          error.code = 'PERFORMANCE_SOLD_OUT'
          error.message = 'That performance is sold out.'
          return false
        }
        const settingStore = useSettingStore()
        if (!this.isPerformanceVisibleOnTheList(performance)) {
          error.code = 'PAST_PERFORMANCE'
          error.message = 'That performance is in the past.'
          return false
        }
        this.performance = performance
        if (settingStore.max_guests == 1) {
          await this.refreshPerformance()
          // for livestreaming venues we don't ask for number of guests, just assume 1
          return await this.setNumberOfGuests(1, error)
        } else {
          // if we are not livestreaming, we ask for number of guests every time
          this.clearNumberOfGuests()
          await this.$router.push(this.nextRoute)
          return true
        }
      } catch (newError) {
        console.error(newError)
        Sentry.captureException(newError)
        error.code = 'UNKNOWN'
        error.message = 'An error occured'
        return false
      }
    },
    async setNumberOfGuests(numberOfGuests: number, error: ErrorData) {
      clearError(error)
      gtmEvt('setNumberOfGuests')
      this.number_of_guests = numberOfGuests
      if (this.standingRoomOnly) {
        // if there is only one table and it is standing room only, we skip table selection
        return await this.setTables([this.performance!.tables![0].id], error)
      } else {
        this.clearTables()
        await this.$router.push(this.nextRoute)
        return true
      }
    },
    async setTables(tables: PerformanceTableId[], error: ErrorData) {
      clearError(error)
      gtmEvt('setTables')
      this.tables = tables
      try {
        await this.createAnonymousReservation()
      } catch (e) {
        this.performance = await this.getPerformance(this.performance!.id)
        this.clearTables()
        throw e
      }
      if (
        useSettingStore().flagEnabled(FeatureFlag.SKIP_SEATING_TIME) &&
        this.performance?.seating_times?.length === 1
      ) {
        return await this.setSeatingTime(this.performance.seating_times[0], error)
      } else {
        this.clearSeatingTime()
        await this.$router.push(this.nextRoute)
        return true
      }
    },

    async setSeatingTime(seatingTime: StorePerformanceSeatingTime, error: ErrorData) {
      clearError(error)
      gtmEvt('setSeatingTime')
      if (seatingTime.sold) {
        error.code = 'SEATING_TIME_SOLD_OUT'
        error.message = 'That seating time is sold out.'
        return false
      }
      this.seating_time = seatingTime
      this.clearAddOns()
      await this.$router.push(this.nextRoute)
      return true
    },
    async setAddOns(addOns: any[], error: ErrorData) {
      clearError(error)
      gtmEvt('setAddOns')
      this.add_ons = addOns
      this.clearReservation()
      await this.$router.push(this.nextRoute)
      return true
    },

    setUser(user: StoreUser) {
      this.contact.first_name = user.first_name || this.contact.first_name
      this.contact.last_name = user.last_name || this.contact.last_name
      this.contact.email = user.email || this.contact.email
      this.contact.phone = user.phone || this.contact.phone
    },
    setPromoCode(promo: StorePromotionCode | null) {
      if (!promo) this.promo_code = null
      this.promo_code = promo
    },
    setInviteCode(code: string | undefined) {
      this.invite_code = code || null
    },
    async validatePromotionCode(code: string): Promise<PromotionCodeRead> {
      return (await api.validatePromotionCode(code)).data
    },
    setScrollPositionFromSetShow(position: number | null) {
      this.scroll_position_from_set_show = position
    },

    handleStripeResponse(stripeResp: stripe.PaymentIntentResult) {
      if (stripeResp.error) {
        gtmEvt('paymentFailure')
        throw enhanceStripeError(stripeResp.error)
      } else {
        gtmEvt('paymentSuccess')
      }
    },

    async ensureReservation() {
      const response = await api.guestModifyReservation(
        this.reservation!.id,
        this.reservationGuestData,
      )
      this.reservation = response.data
      if (!useUserStore().isLoggedIn && this.reservation.contact) {
        try {
          window.hj('identify', `g-${this.reservation.contact.id}`, {
            email: this.contact.email,
          })
        } catch {
          // shrug
        }
      }
    },

    async checkout(callback: () => Promise<stripe.PaymentIntentResult>) {
      await this.ensureReservation()
      if (this.requiresPayment) {
        this.handleStripeResponse(await callback())
      }
      this.checkout_success = true
      await this.$router.push('/order-details')

      if (!window.dataLayer || !this.performance) return
      window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currency: useSettingStore().venue_currency,
          transaction_id: this.reservation?.id,
          value: this.pricing.total,
          items: [
            {
              item_id: this.performance.id,
              item_name: this.performance.show.name,
              item_variant: this.tableModels.map(t => t.name).join(', '),
              price: this.pricing.total,
            },
          ],
        },
      })
    },

    setIsDesktopFlow(isDesktopFlow: boolean) {
      this.is_desktop_flow = isDesktopFlow
    },

    async createAnonymousReservation() {
      if (!this.performance) throw new Error('No performance is set')
      if (!this.reservation || this.reservation.performance_id !== this.performance.id) {
        const response = await api.reserveReservation({
          performance: this.performance.id,
          number_of_guests: this.number_of_guests,
          tables: this.tables,
          tables_details: this.tableModels.map(t => ({
            id: t.id,
            name: t.name,
            guests: t.guests,
            price_per_person: t.price_per_person,
          })),
          skip_guest: true,
          ...(this.invite_code && { invite_code: this.invite_code }),
          ...toReservationParams(this.pricing),
        })
        this.reservation_key = response.data.reservation_key
        this.reservation = response.data.reservation
        this.client_secret = response.data.client_secret
      } else {
        const response = await api.guestModifyReservation(
          this.reservation.id,
          this.reservationGuestData,
        )
        this.reservation = response.data
      }
    },

    async abandonAnonymousReservation() {
      if (this.checkout_success || !this.reservation || !this.reservation_key) return
      await api.abandonReservation(this.reservation.id, { reservation_key: this.reservation_key })
      this.reservation = null
      this.reservation_key = null
      this.client_secret = null
    },
  },
})
