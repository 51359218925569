<template>
  <section>
    <StatusBar v-if="!isCheckoutDesktopFlow" />
    <section :class="{ 'px-4': !isCheckoutDesktopFlow }">
      <h1 class="text-2xl md:text-4xl font-bold font-heading mb-2">
        Please select optional add-ons.
      </h1>
      <div
        v-for="addOn in performanceAddOns"
        :id="`performance-${currentPerformance.id}-addOn-${addOn.id}`"
        :key="addOn.id"
        class="mb-8"
      >
        <div :id="`addOn-${addOn.id}-name`" class="font-sans font-semibold mb-2">
          {{ addOn.name }}
        </div>
        <div :id="`addOn-${addOn.id}-price`" class="font-sans text-sm mb-3">
          {{ currency(addOn.price) }} each
        </div>
        <div
          :id="`addOn-${addOn.id}-description`"
          class="font-sans text-sm mb-2 whitespace-pre-line"
        >
          {{ addOn.description }}
        </div>
        <div class="flex justify-between">
          <div :id="`quantity-selector-${addOn.id}`">
            <QuantitySelector
              :id="addOn.id"
              :modelValue="addOn.quantity"
              @update:modelValue="onAddOns(addOn.id, $event)"
            />
          </div>
          <div>
            <div class="font-sans font-semibold">
              {{ currency(Number(addOn.quantity) * addOn.price) }}
            </div>
          </div>
        </div>
      </div>
      <CheckoutBottomBar @click:next="onNext" />
    </section>
  </section>
</template>
<script lang="ts">
import * as Sentry from '@sentry/vue'
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import ButtonDefault from '@/common/components/atoms/ButtonDefault.vue'
import { StorePerformance } from '@/models'
import QuantitySelector from '@/modules/guestUi/components/molecules/QuantitySelector.vue'
import CheckoutBottomBar from '@/modules/guestUi/components/organisms/CheckoutBottomBar.vue'
import StatusBar from '@/modules/guestUi/components/organisms/StatusBar.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { blankError } from '@/utils/errors'
import { deleteById, sortBy, upsertById } from '@/utils/utils'

import { PerformanceAddOnId, PerformanceAddOnRead, ReservationAddOnWrite } from '@generated/types'

interface SelectedAddOnsClean extends PerformanceAddOnRead {
  quantity: number
}

interface OnSelectedAddOnsWrite extends ReservationAddOnWrite {
  id: PerformanceAddOnId
}

export default defineComponent({
  name: 'AddOns',
  components: {
    CheckoutBottomBar,
    ButtonDefault,
    QuantitySelector,
    StatusBar,
  },
  data() {
    return {
      selectedAddOns: [] as OnSelectedAddOnsWrite[],
      ui: {
        error: blankError(),
      },
    }
  },
  async created() {
    if (!this.checkoutStore.performance) {
      Sentry.captureException(new Error(`Arrived at SetAddOns with no performance set`))
      await this.$router.replace('/')
      return
    }
    await this.checkoutStore.refreshPerformance()
  },
  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),
    ...mapState(useSettingStore, ['currency']),

    currentPerformance(): StorePerformance {
      if (!this.checkoutStore.performance) {
        this.$router.replace('/')
        throw new Error("Can't be in SetAddOns without a performance")
      }
      return this.checkoutStore.performance
    },

    performanceAddOns(): SelectedAddOnsClean[] {
      return sortBy(
        this.checkoutStore.activeAddOns.map(addOn => ({
          ...addOn,
          quantity:
            this.selectedAddOns?.find(
              selectedAddOn => selectedAddOn.performance_add_on_id == addOn.id,
            )?.quantity || 0,
        })),
        'name',
      )
    },

    isCheckoutDesktopFlow(): boolean {
      return this.checkoutStore.isDesktopFlow
    },
  },
  methods: {
    onAddOns(addOnId: PerformanceAddOnId, event: number) {
      if (!addOnId) return
      const obj = {
        id: addOnId,
        performance_add_on_id: addOnId,
        quantity: event,
      }
      event > 0 ? upsertById(obj, this.selectedAddOns) : deleteById(obj.id, this.selectedAddOns)
    },

    onBack() {
      history.go(-1)
    },

    onNext() {
      this.checkoutStore.setAddOns(
        this.selectedAddOns.map(addOn => ({
          performance_add_on_id: addOn.performance_add_on_id,
          quantity: addOn.quantity,
        })),
        this.ui.error,
      )
    },
  },
})
</script>
