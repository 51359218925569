<template>
  <div
    class="flex justify-start bg-body-primary border-t border-linear-g-primary p-4 fixed w-full -ml-4 bottom-0 lg:relative lg:p-0 lg:border-none lg:m-0 lg:mt-24"
  >
    <ButtonDefault
      id="back-button"
      label="Back"
      class="mr-auto font-semibold uppercase text-sm px-4 py-1.5 border border-solid border-button-guest-primary rounded font-button text-button-guest-primary"
      guest
      @click="onBack"
    />
    <ReservationCheckoutTimer v-if="reservation && display_timer" class="mr-auto" />
    <ButtonDefault
      id="next-button"
      class="px-4 uppercase border border-solid rounded font-semibold bg-button-guest-primary font-button text-button-label border-button-guest-primary disabled:bg-disabled disabled:cursor-not-allowed disabled:border-disabled disabled:text-white"
      :class="{ invisible: hideNextButton }"
      :label="nextButtonText"
      @click="emit('click:next')"
      :disabled="isNextButtonDisabled"
      guest
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import ButtonDefault from '@/common/components/atoms/ButtonDefault.vue'
import ReservationCheckoutTimer from '@/modules/guestUi/components/molecules/ReservationCheckoutTimer.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'

const emit = defineEmits<{
  (e: 'click:next'): void
}>()

withDefaults(
  defineProps<{
    nextButtonText?: string
    isNextButtonDisabled?: boolean
    hideNextButton?: boolean
  }>(),
  {
    nextButtonText: 'Next',
    isNextButtonDisabled: false,
    hideNextButton: false,
  },
)

const { reservation } = storeToRefs(useCheckoutStore())
const { display_timer } = storeToRefs(useSettingStore())

function onBack() {
  history.go(-1)
}
</script>
