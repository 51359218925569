<template>
  <section class="max-w-screen-sm mx-auto px-4 md:px-0 xl:container xl:pt-10">
    <div
      v-if="show"
      class="w-full flex flex-col space-y-6 xl:flex-row xl:justify-start xl:items-start xl:space-y-0"
    >
      <div class="hidden w-96 xl:block">
        <picture>
          <source :srcset="show.srcset.sqLg.src" type="image/webp" />
          <img :alt="show.name" :src="show.image" class="rounded-md" />
        </picture>
      </div>
      <div class="xl:shrink-[20] xl:px-12">
        <h1 id="show-title" class="text-3xl font-semibold font-heading">{{ show.name }}</h1>

        <h2
          id="show-date"
          class="uppercase text-base font-semibold mb-3 font-date text-fg-disclaimer"
        >
          {{ dateStr }}
        </h2>

        <div class="flex flex-wrap gap-2 sm:gap-4 mb-4">
          <PerformanceButton
            v-for="performance in performances"
            :key="performance.id"
            :performance="performance"
            :error="error"
          />
        </div>

        <picture class="xl:hidden">
          <source :srcset="show.srcset.rectSm.src" type="image/webp" />
          <img :alt="`${show.name} Cover`" :src="show.image" class="w-full" />
        </picture>

        <p
          id="show-description"
          class="text-sm md:text-base mt-4 mb-2 font-sans whitespace-pre-line"
        >
          {{ show.description }}
        </p>

        <div v-if="error.message">
          {{ error.message }}
        </div>
      </div>
      <template v-if="performanceDates.length > 1">
        <hr class="h-1 w-full bg-surface-base xl:hidden" />
        <div class="xl:ml-auto xl:w-72 xl:pt-28">
          <h2 class="font-heading font-semibold text-lg uppercase mb-4">More Performances</h2>
          <div class="flex flex-col mb-4">
            <router-link
              v-for="performanceDate in performanceDates"
              :key="performanceDate"
              :id="`show-performance-date-${performanceDate}`"
              class="cursor-pointer"
              :class="performanceDate === date ? 'text-fg-g-primary font-semibold' : 'text-primary'"
              :to="{ name: 'showDetails', params: { showId }, query: { date: performanceDate } }"
            >
              {{ settingStore.longDate(performanceDate) }}
            </router-link>
          </div>
        </div>
      </template>
    </div>
    <template v-else-if="loading">
      <Spinner></Spinner>
    </template>
    <template v-else>
      Something went wrong, there do not seem to be any matching performances.
    </template>
  </section>
</template>

<script lang="ts">
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import Spinner from '@/common/components/atoms/Spinner.vue'
import { StorePerformance, StoreShow } from '@/models'
import PerformanceButton from '@/modules/guestUi/components/organisms/PerformanceButton.vue'
import { useCheckoutStore } from '@/stores/checkout'
import { useSettingStore } from '@/stores/setting'
import { sortBy } from '@/utils/utils'

export default defineComponent({
  name: 'ShowDetails',
  data() {
    return {
      loading: true,
      error: {
        code: '',
        message: '',
      },
    }
  },
  async serverPrefetch() {
    await this.loadShowDetails()
  },
  async created() {
    await this.loadShowDetails()
  },
  methods: {
    async loadShowDetails() {
      await this.checkoutStore.getPerformances({
        start_date: this.date,
        show: this.showId,
        pagination: 'false',
      })
      this.loading = false

      if (this.show) {
        this.settingStore.ssrTitle = `${this.show.name} - ${this.dateStr} - ${this.settingStore.venue_name}`
        this.settingStore.ssrImage = this.show.srcset.rectLg.src
        this.settingStore.ssrDescription = this.show.description!
      }
    },
  },
  computed: {
    ...mapStores(useSettingStore, useCheckoutStore),
    showId(): number {
      return Number(this.$route.params.showId)
    },
    date(): string {
      if (!this.$route.query.date) {
        return ''
      }
      if (this.$route.query.date.length > 10) {
        return this.$route.query.date.toString().substring(0, 10)
      }
      return this.$route.query.date.toString()
    },
    dateStr(): string {
      return this.settingStore.mediumDate(this.date)
    },
    performances(): StorePerformance[] {
      return this.checkoutStore.performances.filter(
        performance => performance.show_id == this.showId && performance.date == this.date,
      )
    },
    performanceDates(): string[] {
      return [
        ...new Set(
          sortBy(this.checkoutStore.performances, 'date')
            .filter(performance => performance.show_id === this.showId)
            .map(performance => performance.date),
        ),
      ]
    },
    show(): StoreShow | null {
      if (this.performances.length) {
        return this.performances[0].show
      }
      return null
    },
  },
  components: { Spinner, PerformanceButton },
})
</script>
